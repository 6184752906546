*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    background-color: transparent ;

}

html{
    scroll-behavior: smooth;
    padding-top: 75px;
}
body{
    font-weight: 400;
    overflow-x: hidden;
    position: relative;
    background-image: url('https://ik.imagekit.io/b80sh2n2k/banner-bg.png?updatedAt=1679470984528') !important;
    background-size: cover;
    background-position: top center;
    color: #fff !important;
    font-family: 'Montserrat', sans-serif !important;
    background-color: black ;
}
h1 , h2,h3,h4,h5,h6{
    font-family: 'PT Sans', sans-serif;
    line-height: normal;
}
.project-title{
    opacity: 0;
    transition: all 1s linear ;
}
.project-title:hover{
    opacity: 1;
    background-image: linear-gradient(90.21deg, rgba(170, 54, 124, 0.5) -5.91%, rgba(74, 47, 189, 0.5) 111.58%);
}


.proj-imgbx {
    position: relative;
    border-radius: 30px;
    overflow: hidden;
    margin-bottom: 24px;
  }
  .proj-imgbx::before {
    content: "";
    background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
    opacity: 0.85;
    position: absolute;
    width: 100%;
    height: 0;
    transition: height 0.4s ease-in-out;
  }
  .proj-imgbx:hover::before {
    height: 100%;
  }
  .proj-txtx {
    position: absolute;
    text-align: center;
    top: 65%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: 0.5s ease-in-out;
    opacity: 0;
    width: 100%;
  }
  .proj-imgbx:hover .proj-txtx {
    top: 50%;
    opacity: 1;
  }
  .proj-txtx h4 {
    font-size: 30px;
    font-weight: 700;
    letter-spacing: 0.8px;
    line-height: 1.1em;
  }
  .proj-txtx span {
    font-style: italic;
    font-weight: 400;
    font-size: 15px;
    letter-spacing: 0.8px;
  }


  /* tab editing */
  .project {
    padding: 80px 0;
    position: relative;
    background-color: black;
  }
  .project h2 {
      font-size: 45px;
      font-weight: 700;
    text-align: center !important;
    width: 100% !important;
  }
  .project p {
    color: #B8B8B8;
    font-size: 18px;
    letter-spacing: 0.8px;
    line-height: 1.5em;
    margin: 14px auto 30px auto;
    text-align: center;
    width: 56%;
  }
  .project .nav.nav-pills {
    /* width: 72%; */
    margin: 0 auto;
    border-radius: 50px;
    background-color: rgb(255 255 255 / 10%);
    overflow: hidden;
  }
  .project .nav.nav-pills .nav-item {
    width: 33.33333%;
  }
  .project .nav.nav-pills .nav-link {
    background-color: transparent;
    border-radius: 0;
    padding: 17px 0;
    color: #fff;
    width: 100%;
    font-size: 17px;
    letter-spacing: 0.8px;
    font-weight: 500;
    position: relative;
    transition: 0.3s ease-in-out;
    text-align: center;
    z-index: 0;
  }
  .project .nav.nav-pills .nav-link::before {
      content: "";
      position: absolute;
      width: 0;
      height: 100%;
      background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
      top: 0;
      left: 0;
      z-index: -1;
      transition: 0.3s ease-in-out;
  }
  .project .nav.nav-pills .nav-link.active::before {
      width: 100% !important;
  }
  .project .nav.nav-pills .nav-link.active {
      border: 1px solid rgba(255, 255, 255, 1);
  }
  .nav-link#projects-tabs-tab-first {
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 55px 0px 0px 55px;
  }
  .nav-link#projects-tabs-tab-second {
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  }
  .nav-link#projects-tabs-tab-third {
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 0 55px 55px 0;
  }



  /* /contact  */
.contact form input , textarea{
    background: hsla(0,0%,100%,.1);
    border: 1px solid hsla(0,0%,100%,.5);
    border-radius: 20px;
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: .8px;
    margin: 0 0 8px;
    padding: 18px 26px;
    transition: .3s ease-in-out;
    width: 100%;
}